/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, subtitle, image, siteUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            subtitle
            description
            author
            twitterUsername
            image
            siteUrl
          }
        }
      }
    `
  )

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaSubtitle = subtitle || site.siteMetadata.subtitle
  const defaultTitle = title || site.siteMetadata?.title
  const defaultImage = image || site.siteMetadata?.image
  const twitterImage = image || site.siteMetadata?.twitterImage
  const defaultURL = site.siteMetadata?.siteUrl
  const defaultUsername = site.siteMetadata?.twitterUsername

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={metaSubtitle ? `${defaultTitle} - ${metaSubtitle}` : defaultTitle ? `${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `subtitle`,
          content: metaSubtitle,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:subtitle`,
          content: metaSubtitle,
        },
        {
          property: `og:image`,
          content: defaultImage,
        },
        {
          property: `og:url`,
          content: defaultURL,
        },
        {
          property: `og:image:alt`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `630`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: defaultUsername,
        },
        {
          name: `twitter:site`,
          content: defaultUsername,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:image`,
          content: twitterImage,
        },
        {
          name: `twitter:image:alt`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}


SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  subtitle: ``,
  image: ``,
  siteUrl: ``,
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
}

export default SEO

