import React, { useState, useRef } from "react"
import { Link, navigate } from "gatsby"
import Logo from '../images/logo.svg';
import Toggle from '../images/nav-toggle.svg';
import scrollTo from 'gatsby-plugin-smoothscroll';
import useOnClickOutside from './onClickOutside';

const Header = () => {
  const ref = useRef()
  const [isToggle, setToggle] = useState(false)
  useOnClickOutside(ref, () => setToggle(false))
  return (
    <header>   
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="right-menus">
          <div className="btn">
            <Link to="https://app.adjust.com/betg9vk?label=getapp&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fpt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8">
              Try For Free
            </Link>
          </div>
          <div className="nav-toggle" onClick={() => setToggle(true)} aria-hidden="true">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div className={isToggle ? "drop-menu show": "drop-menu"} ref={ref}>
        <div className="nav-toggle toggled" onClick={() => setToggle(false)} aria-hidden="true">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div 
          className="menu-item" 
          onClick={() => { navigate("/"); setToggle(false)}}
        >
          Home
        </div>
        <div 
          className="menu-item" 
          onClick={() => { navigate("/about"); setToggle(false)}}
        >
            What is MealPrepPro
        </div>

        <a href="https://mealpreppro.groovehq.com/help/mealpreppro-on-android" target="_blank">
          <div className="menu-item">
            MealPrepPro on Android
          </div>
        </a>

        <a href="https://app.adjust.com/betg9vk?label=getapp&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fpt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8">
          <div className="btn">
            Get The App
          </div>
        </a>
      </div>
    </header>
  )
}

export default Header
